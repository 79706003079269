.Login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2em;
  width: 100%;
  font-family: "Staatliches", sans-serif;
  color: #fff;
  &-wrap {
    position: fixed;
    background: rgba(#000, .95);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &-input {
    border-radius: 5px;
    border: 0;
    appearance: none;
    padding: .5em;
  }
  &-input,
  &-btn {
    margin: .5em 0;
  }
  &-title {
    color: #fff;
    font-size: 2em;
    margin: 0 0 .25em;
  }
  &-btnWrap {
    justify-content: center;
    display: flex;
  }
  &-btn {
    font-family: inherit;
    border: 0;
    padding: 12px 24px;
    border-radius: 5px;
    background-color: #dcb43e;
    margin: .5em;
    font-size: 1.25rem;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    background: #13166f;
  }
}

.u-noScroll {
  overflow: hidden;
}