.Results {
  list-style: none;

  &--skip {
    opacity: 0.6;
    text-decoration: line-through;
  }
  &-btnWrap {
    display: flex;
    justify-content: center;
  }
  &-shuffleIcon {
    width: 25px;
    height: 25px;
    fill: #296eb4;
    margin-right: .25em;
    top: -1px;
    position: relative;
  }
  &-btn {
    font-family: inherit;
    border: 0;
    padding: 12px 24px;
    border-radius: 5px;
    background-color: #fff;
    margin: 16px 6px 6px;
    font-size: 2rem;
    color: #1789FC;
    line-height: 1;
    &--go {
      position: relative;
      background-color: #dcb43e;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-size: cover;
        background-image: url("images/stars.svg");
        background-position: center center;
      }
    }
    &-text {
      position: relative;
      z-index: 1;
    }
    &--reshuffle {
      margin: 1em auto 0;
      font-size: 1.5em;
      border-radius: 5px 5px 0 0;
      display: flex;
      align-items: center;

      &:focus {
        background-color: #1789FC;
        color: #fff;

        .Results-shuffleIcon {
          fill: #fff;
        }
      }
    }
  }
  &-list {
    padding: 0;
    margin: 0;
    height: calc(100vh - 186px);
    font-size: 1.75rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
}
